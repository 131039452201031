import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PCMH Restore Health believes in contributing to the society in whatever measures possible.  Our clinical team visits the Karnataka Network for People Living With HIV/AIDS (KNP+) clinic in Yeshwanthpur to conduct a free clinic for HIV positive individuals.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/473680c055d5035fd0bf5b87065fd8eb/08b4d/weekly-thursday-clinic-at-yeshwanthpur-1.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRrQAAABXRUJQVlA4IKgAAABwBACdASoUAA8APtFUo0uoJKMhsAgBABoJaAC1A1/DyRGqh4miIr05gh85gAD+0fkWOhpvuspw2qOoGM8oN7mZN30G1k7tLWt0hDP+6q44xPB36QSh1p10DU0a6MqbJ83BhzZt1mL98dX3E2qlzuamd6oYG0MQAe3GLcqdvYQdSbZp+FhCIuunvRDX52dZqXECrtrgJ2vGLoQVN+YJL+CdUAhVto53wAA=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "weekly thursday clinic at yeshwanthpur 1",
            "title": "weekly thursday clinic at yeshwanthpur 1",
            "src": "/static/473680c055d5035fd0bf5b87065fd8eb/08b4d/weekly-thursday-clinic-at-yeshwanthpur-1.webp",
            "srcSet": ["/static/473680c055d5035fd0bf5b87065fd8eb/c85cb/weekly-thursday-clinic-at-yeshwanthpur-1.webp 300w", "/static/473680c055d5035fd0bf5b87065fd8eb/e88ff/weekly-thursday-clinic-at-yeshwanthpur-1.webp 600w", "/static/473680c055d5035fd0bf5b87065fd8eb/08b4d/weekly-thursday-clinic-at-yeshwanthpur-1.webp 750w"],
            "sizes": "(max-width: 750px) 100vw, 750px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`KNP+ strives to help those with HIV in multiple spheres of their lives, focusing on systematic and continuous development of people living with HIV/AIDS at local, district and state levels.  Presently, KNP+ has 28 District Level Networks affiliated across Karnataka, and extends continuous support to strengthen them. In these 12 years KNP+ registered a tremendous growth in terms of registration, more than 50,000 people living with HIV/AIDS (PLHA) are coming together under the umbrella of confidence and hope which is the great strength for KNP+. To know more about them visit: `}<a parentName="p" {...{
        "href": "http://www.knpplus.in/introduction"
      }}>{`http://www.knpplus.in/introduction`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4d45f589add482cb14cc85567abc2d51/ca4a8/weekly-thursday-clinic-at-yeshwanthpur-2.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRiwBAABXRUJQVlA4ICABAACwBQCdASoUABsAPtFap04oJSOiKAqpABoJbACdMtplrAqjYdK+UXK2QmDklrOEETD5UOS9UAAA/uiBedMMFfEazwm4yr9Q+rVRdgSOIfMdQf2sRx13eCbEtv6XclJ1tVB3cDCf7sr57VKP4tv8pd7QH15tdwdsQhBB6b3+7tLi2RhOzncQPCEIR3yD4AFCSvSUvmes1iqxGMtA560R1OyQHJ+oicti2Rn53OrpwaDIWX/c2fm/KO7q9ub3Ow3oNJ1B0RbLTSBgP9Jt7il0jZ0FCaTWPPiRE9yVr5BHa3LhvlpsCyeBcu3eHjvzLTQSFu+EaVjOt5KDO0FqYk/cev836fs2cEAfs3+R6qEwZMzdPFkqt7Bp6bnILvb/XMbKAAA=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "weekly thursday clinic at yeshwanthpur 2",
            "title": "weekly thursday clinic at yeshwanthpur 2",
            "src": "/static/4d45f589add482cb14cc85567abc2d51/ca4a8/weekly-thursday-clinic-at-yeshwanthpur-2.webp",
            "srcSet": ["/static/4d45f589add482cb14cc85567abc2d51/c85cb/weekly-thursday-clinic-at-yeshwanthpur-2.webp 300w", "/static/4d45f589add482cb14cc85567abc2d51/e88ff/weekly-thursday-clinic-at-yeshwanthpur-2.webp 600w", "/static/4d45f589add482cb14cc85567abc2d51/ca4a8/weekly-thursday-clinic-at-yeshwanthpur-2.webp 740w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you wish to refer individuals who are HIV positive with limited affordability, this is the address :  1st Floor, APMC Old Administrative Office, Near RMC Yard Police Station, Tumkur Road, Yashwantapur, Bangalore - 22. Timings : Thursday 3:00 PM to 5:00 PM or contact us at PCMH Restore Health to know more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      